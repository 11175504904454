@import url('./fsco-components/assets/primeng-fsco-theme.css');
@import '~jsoneditor/dist/jsoneditor.min.css';

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}

*:focus { outline: none; }

body {
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    color: var(--text-color);
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: var(--surface-d);
}

::-webkit-scrollbar-thumb {
    background: var(--secondary-color);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--gray-600);
}

.p-dialog {
    .p-dialog-content {
        .p-message {
            margin-left: 0rem !important;
            margin-right: 0rem !important;
            .p-message-wrapper {
                box-shadow: none;
                .p-message-summary {
                    display: none;
                    .p-message-summary {
                        margin: 0;
                    }
                }
                .p-message-detail {
                    margin-left: 1rem;
                }
            }
        }
    }
}

.p-message {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
    border-width: 0 !important;

    .p-message-wrapper {
        border-left: none;
        border-radius: var(--radius-border-round-lg, 0.5rem);
        box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);

        font-family: SuisseIntl;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        .p-message-summary {
            margin-left: 1rem;
            margin-right: 0.5rem;
        }
    }
}

.p-toast {
    .p-toast-message {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
        border-width: 0 !important;

        .p-toast-message-wrapper {
            border-left: none;
            border-radius: var(--radius-border-round-lg, 0.5rem);
            box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04),
                0px 20px 25px -5px rgba(0, 0, 0, 0.1);

            font-family: SuisseIntl;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;

            .p-message-toast-summary {
                margin-left: 1rem;
                margin-right: 0.5rem;
            }
        }
    }
}

.p-message-success .p-message-wrapper {
    background: var(--palette-green-50, #f4fcf7);
    color: var(--light-text-success-text, var(--palette-green-700, #188a42));
}

.p-message-error .p-message-wrapper {
    background: var(--palette-red-50, #fef6f6);
    color: var(--light-text-danger-text, var(--palette-red-700, #a73030));
}

.p-message-content .p-message-wrapper {
    background: var(--palette-blue-50, #f5f9ff);
    color: var(--light-text-info-text, var(--palette-blue-700, #295bac));
}
.p-message-warning .p-message-wrapper {
    background: var(--palette-orange-50, #fff8f3);
    color: var(--light-text-warning-text, var(--palette-orange-600, #d46213));
}

.custom-table-tooltip {
    &-success {
        .p-tooltip-text {
            background-color: var(--green-600) !important;
            height: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &-warning {
        .p-tooltip-text {
            background-color: var(--orange-600) !important;
            height: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.p-progress-spinner-circle{
    stroke: var(--gray-400) !important;
}

.p-dropdown:focus,
.p-dropdown:focus-visible {
  outline: none;
}

/**
* Fix for JSON Editor Height
*/
textarea.jsoneditor-text{ min-height:30rem; }
div.jsoneditor-mode-code{ height:30rem; }